/* global _ */
'use strict'
/** @ngInject */
function uploadController ($scope, $translate, Resource, FileParser) {
  $scope.caption = $scope.to.caption || 'Upload'
  $scope.container = $scope.to.container || null
  $scope.disabled = $scope.to.disabled || false
  $scope.runFileParser = $scope.to.runFileParser || false
  $scope.parserInstantRun = $scope.to.parserInstantRun
  $scope.fileParser = $scope.to.fileParser || null
  $scope.workflowSessionItemId = $scope.to.workflowSessionItemId || null
  $scope.formDataId = $scope.to.formDataId || null
  $scope.keepOriginal = _.isNil($scope.to.keepOriginal)
    ? true
    : $scope.to.keepOriginal
  $scope.uniqueFiles = $scope.to.uniqueFiles || false

  $scope.filePreviewObject = {}
  let modelName = null
  let modelId = null
  if (!_.isNil($scope.workflowSessionItemId)) {
    modelName = 'WorkflowSessionItem'
    modelId = $scope.workflowSessionItemId
  } else if (!_.isNil($scope.formDataId)) {
    modelName = 'FormData'
    modelId = $scope.formDataId
  }
  const initialValue = _.get($scope.model, $scope.options.key)
  $scope.parseFile = async function parseFile () {
    const resourceId = _.get($scope.model, $scope.options.key)
    try {
      // eslint-disable-next-line
      await FileParser.parseFileWithParser({
        resourceId,
        fileParserId: $scope.fileParser,
        modelName,
        modelId
      }).$promise
    } catch (ex) {
      if ($scope.options.validation) {
        $scope.options.validation.errorExistsAndShouldBeVisible = true
        $scope.options.validation.messages = [
          {
            field: $scope.options.key,
            value: ex.data.error.message
          }
        ]
        // $scope.options.formControl.$setValidity('parserError', false)
      }
      _.set($scope.model, $scope.options.key, null)
      if ($scope.to.object) {
        $scope.model[$scope.to.object] = []
      }
      throw ex
    }
  }
  $scope.formlyFileSelected = function formlyFileSelected (results) {
    return new Promise(async (resolve, reject) => {
      try {
        if (results && results.length > 0) {
          if ($scope.to.mode === 'multiple') {
            _.set(
              $scope.model,
              $scope.options.key,
              results.map(r => r.id)
            )
          } else {
            _.set($scope.model, $scope.options.key, results[0].id)
          }
          if ($scope.runFileParser && initialValue !== results[0].id) {
            $scope.options.validation.errorExistsAndShouldBeVisible = false

            if ($scope.to.parserInstantRun) {
              $scope.parseFile()
            }
          }
        } else {
          _.set($scope.model, $scope.options.key, null)
        }
        if ($scope.to.onFileSelect) {
          $scope.to.onFileSelect(results, $scope.options)
        }
        resolve()
      } catch (ex) {
        console.error(ex)
        reject(ex)
      }
    })
  }

  $scope.$watch('model', async function (newValue, oldValue, scope) {
    if (
      newValue &&
      oldValue &&
      (!_.isEqual(newValue[$scope.options.key], oldValue[$scope.options.key]) ||
        $scope.filePreviewObject[$scope.to.object] !==
          newValue[$scope.to.object])
    ) {
      if (_.isEmpty(newValue[$scope.options.key])) {
        $scope.filePreviewObject[$scope.to.object] = {}
      } else if (newValue[$scope.to.object]) {
        $scope.filePreviewObject[$scope.to.object] = newValue[$scope.to.object]
      } else {
        const value = Array.isArray(newValue[$scope.options.key])
          ? newValue[$scope.options.key]
          : [newValue[$scope.options.key]]
        $scope.filePreviewObject[$scope.to.object] = await Resource.find({
          filter: { where: { id: { inq: value } } }
        }).$promise
        $scope.$applyAsync()
      }
    }
  })

  $scope.$watch(
    () => $scope.model[$scope.options.key],
    async (newValue, oldValue) => {
      if (!_.isEqual(newValue, oldValue)) {
        if (!newValue || _.isEmpty(newValue)) {
          $scope.filePreviewObject[$scope.to.object] = {}
        } else {
          const value = Array.isArray(newValue) ? newValue : [newValue]
          $scope.filePreviewObject[$scope.to.object] = await Resource.find({
            filter: { where: { id: { inq: value } } }
          }).$promise
          $scope.$applyAsync()
        }
      }
    }
  )

  if ($scope.to.object && $scope.to.shouldUpdateObject) {
    $scope.$watch(
      'filePreviewObject[to.object]',
      function (newValue, oldValue, scope) {
        if (!_.isEqual(newValue, oldValue)) {
          if (_.isArray(newValue)) {
            if ($scope.to.mode === 'multiple') {
              $scope.model[$scope.to.object] = newValue
            } else {
              $scope.model[$scope.to.object] = newValue[0]
            }
          } else {
            $scope.model[$scope.to.object] = newValue
          }
        }
      }
    )
  }
}

module.exports = uploadController

/* global _ */
require('./customInput.scss')
/** @ngInject */
function customInputController (
  $scope,
  $sanitize,
  visionService,
  FileManagerService,
  AIService,
  $mdToast,
  $rootScope,
  $translate
) {
  $scope.isURL = false
  let resCounter = 0
  $scope.loading = false
  let numberValue = ''
  const isNumber = function isNumber (value) {
    return !isNaN(value) && !isNaN(parseFloat(value))
  }

  $scope.callback = selectedWord => {
    if ($scope.to.type === 'number' && isNumber) {
      selectedWord = parseFloat(selectedWord)
    }
    _.set($scope.model, $scope.options.key, selectedWord)
    if ($scope.to.onChange) {
      $scope.to.onChange(selectedWord, $scope.options)
    }
  }

  $scope.openVisionDialog = async function openVisionDialog () {
    if (
      $scope.to.preVisionDialog &&
      typeof $scope.to.preVisionDialog === 'function'
    ) {
      $scope.to.preVisionDialog()
    }
    if (!$scope.to.disabled) {
      await visionService.openVisionDialog({
        callback: $scope.callback,
        returnWords: false,
        key: `${$scope.options.type}_${$scope.options.key}`
      })
    }
    if (
      $scope.to.postVisionDialog &&
      typeof $scope.to.postVisionDialog === 'function'
    ) {
      $scope.to.postVisionDialog()
    }
  }
  const getAnswerToValue = (textDelta, end) => {
    if (textDelta) {
      if (resCounter === 0) {
        if ($scope.to.type === 'number') {
          numberValue = textDelta
        } else {
          $scope.model[$scope.options.key] = textDelta
        }
      } else {
        if ($scope.to.type === 'number') {
          numberValue += textDelta
        } else {
          $scope.model[$scope.options.key] += textDelta
        }
      }
      resCounter++
    }
    // Check if completion completed
    if (end) {
      if ($scope.to.type === 'number') {
        if (isNumber(numberValue)) {
          $scope.model[$scope.options.key] = parseFloat(numberValue)
        } else {
          $mdToast.show(
            $mdToast.nextplus({
              position: $rootScope.toastLocation,
              parent: 'body',
              theme: 'error-toast',
              hideDelay: 10000
            })
          )
          $mdToast.updateTextContent(
            $translate.instant('Field.ERRORS.VISION_VALUE_CONTAINS_TEXT', {
              value: numberValue
            })
          )
        }
      }
      if ($scope.to.onChange) {
        $scope.to.onChange($scope.model[$scope.options.key], $scope.options)
      }
      $scope.loading = false
      resCounter = 0
    }
  }
  $scope.openVisionAiDialog = async function openVisionAiDialog () {
    if ($scope.loading) {
      return
    }
    const base64 = await FileManagerService.openUploadMenu({}, true)
    if (!base64) {
      return
    }
    $scope.loading = true
    $scope.model[$scope.options.key] = ''
    numberValue = ''
    const settings = await AIService.getPromptSettings('vision')
    const options = {
      max_tokens: settings.maxTokens,
      temperature: settings.temperature
    }
    let prompt = settings.plainPrompt.replace(
      'FIELD_PROMPT',
      $scope.to.aiPrompt
    )
    if ($scope.to.type === 'number') {
      prompt = prompt.concat(', please respond only with a number')
    }
    const completion = new AIService.Completion(
      [
        {
          role: 'system',
          content: [
            {
              type: 'text',
              text: prompt.replace(
                '<AVAILABLE_OPTIONS>',
                `AVAILABLE_OPTIONS: N/A`
              )
            }
          ]
        },
        {
          role: 'user',
          content: [
            { type: 'text', text: $scope.to.aiPrompt },
            {
              type: 'image_url',
              image_url: {
                url: base64
              }
            }
          ]
        }
      ],
      options,
      getAnswerToValue
    )
    completion.send().catch(error => {
      console.error(error)
      $scope.loading = false
    })
    $scope.loading = true
  }

  const setIsURL = function setIsURL (newValue) {
    // Determine if newValue is a URL (http/https). XSS Safe
    $scope.isURL = false
    if (newValue) {
      // Using regex to test if the newValue is a valid HTTP/HTTPS URL
      const urlPattern = /^(http:\/\/|https:\/\/)/i
      if (urlPattern.test(newValue)) {
        // Assuming $sanitize is injected and available in your controller
        try {
          $scope.isURL = true
          $scope.urlValue = $sanitize(newValue) // Sanitizing the URL to prevent XSS
        } catch (e) {
          $scope.isURL = false
        }
      }
    }
  }
  setIsURL($scope.model[$scope.options.key])
  $scope.$watch(
    `model['${$scope.options.key}']`,
    function (newValue, oldValue) {
      if (newValue !== oldValue) {
        setIsURL(newValue)
      }
    }
  )
}

module.exports = customInputController

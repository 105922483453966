/* global flatpickr $ */

/** @ngInject */
function DatePicker () {
  require('./date-picker.scss')
  return {
    template: require('./date-picker.html'),
    bindings: {
      ngModel: '=',
      fpOpts: '<',
      ngDisabled: '='
    },
    controller:
      /**
       * @ngInject
       */
      function DatePicker (
        $scope,
        $element,
        $rootScope,
        $translate,
        DateTimeFormatService
      ) {
        const dateTimeFormats =
          DateTimeFormatService.getDateTimeFormatsForField()
        let fp
        const self = this

        $scope.openPicker = function () {
          if (!self.ngDisabled) {
            fp.open()
          }
        }
        $scope.clearPicker = function () {
          if (!self.ngDisabled) {
            fp.close()
            fp.setDate(null, true)
            $scope.$ctrl.ngModel = undefined
          }
        }

        this.$onChanges = function (changeObj) {
          // Ignore if first change
          if (!changeObj?.ngModel || changeObj.ngModel.isFirstChange()) return

          // On change of ngModel, update the flatpickr instance
          if (changeObj.ngModel && changeObj.ngModel.currentValue) {
            self.ngModel = changeObj.ngModel.currentValue
            fp.setDate(changeObj.ngModel.currentValue)
          }
          // On change of fpOpts, destroy the flatpickr instance and create a new one
          if (changeObj.fpOpts && fp) {
            fp.destroy()
            fp = $($element[0]).flatpickr(changeObj.fpOpts.currentValue)
          }
        }

        $scope.$watch('$ctrl.ngModel', function (newVal, oldVal) {
          if (!fp) return // No flatpickr instance yet? Skip.

          // If both newVal and oldVal are falsy, there's no real change.
          // This handles the null/undefined/empty-string scenario.
          if (!newVal && !oldVal) return

          /**
           * Safely converts a single value to a valid Date or null.
           * - Returns null if the value is falsy or invalid.
           */
          function toValidDate (value) {
            if (!value) return null
            const dateObj = value instanceof Date ? value : new Date(value)
            return isNaN(dateObj.getTime()) ? null : dateObj
          }

          /**
           * Normalizes the model’s value(s) to either:
           * - An array of valid Dates (if array input).
           * - A single Date (if single-value input).
           * - Null if invalid.
           */
          function normalize (value) {
            if (Array.isArray(value)) {
              // Filter out invalids so we don’t end up with [null, validDate].
              return value.map(toValidDate).filter(d => d !== null)
            }
            return toValidDate(value)
          }

          const newDates = normalize(newVal)
          const oldDates = normalize(oldVal)
          const selectedDates = fp.selectedDates

          /**
           * Compares two arrays of Dates by length and exact timestamps.
           * Returns true if both arrays have exactly the same date/time values.
           */
          function compareArrayDates (arr1, arr2) {
            if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false
            if (arr1.length !== arr2.length) return false

            for (let i = 0; i < arr1.length; i++) {
              const d1 = arr1[i]
              const d2 = arr2[i]

              // If both are null (unlikely with our filter, but just in case), treat them as the same.
              if (!d1 && !d2) continue
              if (!d1 || !d2) return false // One is null, the other is not.

              if (d1.getTime() !== d2.getTime()) {
                return false
              }
            }
            return true
          }

          /**
           * Compares potentially single-Date or array-of-Date values,
           * returning true if they represent the same dates.
           */
          function compareDates (val1, val2) {
            // Both arrays
            if (Array.isArray(val1) && Array.isArray(val2)) {
              return compareArrayDates(val1, val2)
            }

            // One array, one single => must have exactly one element matching
            if (Array.isArray(val1) && !Array.isArray(val2)) {
              return (
                val1.length === 1 &&
                val1[0] &&
                val2 &&
                val1[0].getTime() === val2.getTime()
              )
            }
            if (!Array.isArray(val1) && Array.isArray(val2)) {
              return (
                val2.length === 1 &&
                val2[0] &&
                val1 &&
                val2[0].getTime() === val1.getTime()
              )
            }

            // Both single date (or null)
            if (!val1 && !val2) return true // Both are null => same
            if (!val1 || !val2) return false // One is null, other is not

            return val1.getTime() === val2.getTime()
          }

          // Check if the new model value is actually different from oldVal
          // AND also different from the current Flatpickr selected values.
          if (
            !compareDates(newDates, oldDates) &&
            !compareDates(newDates, selectedDates)
          ) {
            // Here, we choose to call setDate with the normalized date objects
            // so we skip extra parsing in flatpickr. If you want to preserve
            // the user’s original strings, call: fp.setDate(newVal, true).
            fp.setDate(newDates, true)
          }
        })

        this.$onInit = async function () {
          this.loadLocalization()
          // if (localization) this.fpOpts.locale = localization
          const lang = $translate.use()
          this.fpOpts.locale = lang
          this.fpOpts.wrap = true
          this.fpOpts.weekNumbers = true
          this.fpOpts.defaultDate = self.ngModel
          this.fpOpts.dateFormat =
            this.fpOpts.dateFormat || dateTimeFormats.date
          this.fpOpts.removeClearOption = this.fpOpts.removeClearOption || false
          $scope.fpOpts = this.fpOpts
          if (!flatpickr) {
            console.error('Unable to find any flatpickr installation')
            return
          }
          fp = $($element[0]).flatpickr(this.fpOpts)
          // fp.setDate(this.ngModel, false)
          fp.config.onChange.unshift(function (
            selectedDates,
            dateStr,
            instance
          ) {
            $scope.$applyAsync(() => {
              if (self.fpOpts.mode === 'range') {
                if (selectedDates.length === 2) {
                  $scope.$ctrl.ngModel = selectedDates
                }
              } else {
                if (selectedDates[0]) {
                  if (self.fpOpts.enableTime && self.fpOpts.noCalendar) {
                    // Time only mode - format as HH:mm
                    const date = selectedDates[0]
                    const hours = date.getHours().toString().padStart(2, '0')
                    const mins = date.getMinutes().toString().padStart(2, '0')
                    $scope.$ctrl.ngModel = `${hours}:${mins}`
                  } else {
                    $scope.$ctrl.ngModel = selectedDates[0]
                  }
                }
              }
            })
          })
        }

        this.loadLocalization = async () => {
          const lang = $translate.use()
          let localization = null
          try {
            if (lang !== 'en') {
              const res = await import(
                /* webpackChunkName: "flatpickr-[request]" */ 'root/node_modules/flatpickr/dist/l10n/' +
                  lang +
                  '.js'
              )
              if (res) {
                localization = res.default[lang]
                flatpickr.default.localize(localization)
              }
            }
          } catch (ex) {
            console.warn(
              `Fail to load user language ${lang}, fallback to en`,
              ex
            )
          }
        }

        // destroy the flatpickr instance when the dom element is removed
        this.$onDestroy = function () {
          fp.destroy()
        }
      }
  }
}

module.exports = DatePicker

const _ = require('lodash')
const modelsHandling = require('root/../common/services/translations/model-special-handling')

/** @ngInject */
function MultiTranslateService ($rootScope, $mdToast, $translate, $injector) {
  let defaultLanguage = $rootScope.appSettings
    ? $rootScope.appSettings.defaultContentLanguage
    : 'en'
  let contentTranslations = $rootScope.appSettings
    ? $rootScope.appSettings.contentTranslations
    : false
  let fallbackTranslation = $rootScope.appSettings
    ? $rootScope.appSettings.fallbackTranslations
    : true
  let availableLanguages = $rootScope.appSettings
    ? $rootScope.appSettings.availableContentLanguages
    : ['en']
  const modelsDeclarationProperties = require('root/../common/services/translations/model-declaration')

  const onSettings = function onSettings () {
    defaultLanguage = $rootScope.appSettings.defaultContentLanguage
    contentTranslations = $rootScope.appSettings.contentTranslations
    fallbackTranslation = $rootScope.appSettings.fallbackTranslations
    availableLanguages = $rootScope.appSettings.availableContentLanguages
  }
  /**
   * Recursion function that create object with formly keys as object's key
   * and path to key as value
   * @param {Array} formlyArray Array of formly fields
   * @param {Object} pathObject Reference to final object
   * @param {String} path String of path so far
   */

  const createPathObject = function createPathObject (
    formlyArray,
    pathObject,
    path
  ) {
    formlyArray.forEach((formlyObj, index) => {
      if (!_.isUndefined(formlyObj.key)) {
        let fullPath = ''
        path ? (fullPath = path + `[${index}]`) : (fullPath = `[${index}]`)
        pathObject[formlyObj.key] = fullPath
      } else if (!_.isUndefined(formlyObj.fieldGroup)) {
        createPathObject(
          formlyObj.fieldGroup,
          pathObject,
          path ? path + `[${index}].fieldGroup` : `[${index}].fieldGroup`
        )
      }
    })
  }
  /**
   * Get all custom fields and create merged properties object
   * @param {Object} model Model instance
   */
  const mergeTranslatableKeys = function mergeTranslatableKeys (model) {
    let properties
    if (typeof model === 'string') {
      properties = $rootScope.appSettings.modelsFields[model].properties
    } else {
      properties = $rootScope.appSettings.modelsFields[model.name].properties
    }
    const customFields = {}
    _.mapKeys(properties, (value, key) => {
      if (value.custom && value.translatable) {
        const keyName = value.key
        customFields[keyName] = ''
      }
    })
    let declarationProperties
    if (typeof model === 'string') {
      declarationProperties = _.cloneDeep(modelsDeclarationProperties[model])
    } else {
      declarationProperties = _.cloneDeep(
        modelsDeclarationProperties[model.name]
      )
    }
    return {
      ...customFields,
      ...declarationProperties
    }
  }
  /**
   * Check if property is translatable and return new key
   * @param {Object} resource Model instance
   * @param {String} language Language code
   * @param {String} property Model property
   */
  const createKeysForGrid = function createKeysForGrid (
    resource,
    language,
    property
  ) {
    const modelsProperties = mergeTranslatableKeys(resource.modelName)
    if (
      contentTranslations &&
      availableLanguages.indexOf(language) > -1 &&
      language !== defaultLanguage &&
      !_.isUndefined(modelsProperties[property])
    ) {
      return `translations.${language}.${property}`
    }
    return property
  }
  /**
   * Switch between keys in formly fields according to current edit language
   * and disable/enable all untranslatable fields
   * @param {Object} model Model instance
   * @param {String} oldLanguage Old language code - currently not in use
   * @param {String} language Language code
   * @param {Object} data Reference of data
   * @param {Array} formly Array of formly fields
   */
  const getForEdit = function getForEdit (
    model,
    oldLanguage,
    language,
    data,
    formly,
    enableDisabled = true
  ) {
    if (contentTranslations) {
      const modelName = model.name
      const modelsProperties = mergeTranslatableKeys(model)
      const pathObject = {}
      createPathObject(formly, pathObject, null)
      const formlyKeys = Object.keys(pathObject)
      _.mapKeys(modelsProperties, (value, key) => {
        const regExp = new RegExp('^translations.[A-z]{2}.' + key)
        const formlyPath = formlyKeys.find(
          string => string === key || string.match(regExp)
        )
        if (formlyPath) {
          const formlyField = _.get(formly, pathObject[formlyPath])
          const newFormly = _.pick(formlyField, [
            'key',
            'type',
            'hide',
            'className',
            'templateOptions',
            'hideExpression'
          ])
          if (language !== defaultLanguage) {
            if (
              !_.isUndefined(modelsHandling[modelName]) &&
              !_.isUndefined(modelsHandling[modelName].Edit) &&
              !_.isUndefined(modelsHandling[modelName].Edit[key])
            ) {
              if (!data.translations) {
                data.translations = {}
              }
              if (!data.translations[language]) {
                data.translations[language] = {}
              }
              data.translations[language][key] = modelsHandling[modelName].Edit[
                key
              ](
                data.translations[language][key],
                data[key],
                data.fallbackStatus,
                language
              )
            } else if (
              !_.isUndefined(data.fallbackStatus) &&
              !_.isUndefined(data.fallbackStatus[language]) &&
              !_.isUndefined(data.fallbackStatus[language][key]) &&
              data.fallbackStatus[language][key]
            ) {
              _.set(data, `translations.${language}.${key}`, value)
            }
            newFormly.key = `translations.${language}.${key}`
          } else {
            // change fallbackStatus when editing saved objects
            if (
              !_.isUndefined(data.fallbackStatus) &&
              !_.isUndefined(data.fallbackStatus[oldLanguage]) &&
              !_.isUndefined(data.fallbackStatus[oldLanguage][key]) &&
              data.fallbackStatus[oldLanguage][key] &&
              // data.translations[oldLanguage][key] !== data[key] &&
              data.translations[oldLanguage][key] !== value
            ) {
              data.fallbackStatus[oldLanguage][key] = false
            }
            newFormly.key = key
          }
          if (!_.isUndefined(formlyField)) {
            _.set(formly, pathObject[formlyPath], newFormly)
          }
        }
      })
      // disable non-editable fields
      if (enableDisabled) {
        const disabledKeys = formlyKeys.filter(function (e) {
          return _.isUndefined(
            this.find(prop => {
              const regExp = new RegExp('^translations.[A-z]{2}.' + prop)
              return prop === e || e.match(regExp)
            })
          )
        }, Object.keys(modelsProperties))
        disabledKeys.forEach(key => {
          const formlyObject = _.get(formly, pathObject[key])
          if (!formlyObject.templateOptions) {
            formlyObject.templateOptions = {}
          }
          formlyObject.templateOptions.disabled = language !== defaultLanguage
          _.set(formly, pathObject[key], formlyObject)
        })
      }
      return { data, formly }
    }
    return { data, formly }
  }
  /**
   * Recursion function that set translations keys instand of regular keys
   * according to current view language and does this for all relations that translatable
   * @param {Object} resource Model instance
   * @param {String} lang Language code
   * @param {Object} data Reference of data
   */
  const getForView = function getForView (resource, lang, data) {
    if (contentTranslations) {
      if (availableLanguages.indexOf(lang) === -1) {
        lang = defaultLanguage
      }
      const relations = resource.model.relations
      const relationsModels = _.isUndefined(relations)
        ? {}
        : Object.keys(relations).reduce(
            (value, key) =>
              Object.assign(value, { [relations[key].model]: key }),
            {}
          )
      const modelsProperties = mergeTranslatableKeys(resource.model)
      if (lang !== defaultLanguage) {
        _.mapKeys(modelsProperties, (value, key) => {
          if (data && data.translations && data.translations[lang]) {
            if (!fallbackTranslation) {
              if (
                !_.isUndefined(modelsHandling[resource.model.name]) &&
                !_.isUndefined(modelsHandling[resource.model.name].View) &&
                !_.isUndefined(modelsHandling[resource.model.name].View[key])
              ) {
                data[key] = modelsHandling[resource.model.name].View[key](
                  data[key],
                  data.translations[lang][key]
                )
              } else {
                data[key] = data.translations[lang][key] || value // without fallback
              }
            } else {
              if (
                data.translations[lang][key] &&
                data.translations[lang][key] !== modelsProperties[key]
              ) {
                if (
                  !_.isUndefined(modelsHandling[resource.model.name]) &&
                  !_.isUndefined(modelsHandling[resource.model.name].View) &&
                  !_.isUndefined(modelsHandling[resource.model.name].View[key])
                ) {
                  data[key] = modelsHandling[resource.model.name].View[key](
                    data[key],
                    data.translations[lang][key]
                  )
                } else {
                  data[key] = data.translations[lang][key]
                }
              }
            }
          }
        })
        for (let i = 0; i < Object.keys(relationsModels).length; i++) {
          const relationName = Object.keys(relationsModels)[i]
          if (
            !_.isUndefined(modelsDeclarationProperties[relationName]) &&
            !_.isUndefined(data[relationsModels[relationName]])
          ) {
            const relationResource = $injector.get(relationName)
            if (_.isArray(data[relationsModels[relationName]])) {
              data[relationsModels[relationName]] = data[
                relationsModels[relationName]
              ].map(relationData =>
                getForView(relationResource, lang, relationData)
              )
            } else {
              data[relationsModels[relationName]] = getForView(
                relationResource,
                lang,
                data[relationsModels[relationName]]
              )
            }
          }
        }
      }
      return data
    }
    return data
  }

  const beforeEdit = function beforeEdit (resource, lang, data) {
    const blacklist = ['Workflow', 'Node']
    if (contentTranslations) {
      if (availableLanguages.indexOf(lang) === -1) {
        lang = defaultLanguage
      }
      const relations = resource.model.relations
      const relationsModels = _.isUndefined(relations)
        ? {}
        : Object.keys(relations).reduce(
            (value, key) =>
              Object.assign(value, { [relations[key].model]: key }),
            {}
          )
      const modelsProperties = mergeTranslatableKeys(resource.model)
      if (lang !== defaultLanguage) {
        if (!blacklist.includes(resource.model.name)) {
          _.mapKeys(modelsProperties, (value, key) => {
            if (data && data.translations && data.translations[lang]) {
              if (!fallbackTranslation) {
                if (
                  !_.isUndefined(modelsHandling[resource.model.name]) &&
                  !_.isUndefined(modelsHandling[resource.model.name].View) &&
                  !_.isUndefined(modelsHandling[resource.model.name].View[key])
                ) {
                  data[key] = modelsHandling[resource.model.name].View[key](
                    data[key],
                    data.translations[lang][key]
                  )
                } else {
                  data[key] = data.translations[lang][key] || value // without fallback
                }
              } else {
                if (
                  data.translations[lang][key] &&
                  data.translations[lang][key] !== modelsProperties[key]
                ) {
                  if (
                    !_.isUndefined(modelsHandling[resource.model.name]) &&
                    !_.isUndefined(modelsHandling[resource.model.name].View) &&
                    !_.isUndefined(
                      modelsHandling[resource.model.name].View[key]
                    )
                  ) {
                    data[key] = modelsHandling[resource.model.name].View[key](
                      data[key],
                      data.translations[lang][key]
                    )
                  } else {
                    data[key] = data.translations[lang][key]
                  }
                }
              }
            }
          })
        }
        for (let i = 0; i < Object.keys(relationsModels).length; i++) {
          const relationName = Object.keys(relationsModels)[i]
          if (
            !_.isUndefined(modelsDeclarationProperties[relationName]) &&
            !_.isUndefined(data[relationsModels[relationName]])
          ) {
            const relationResource = $injector.get(relationName)
            if (_.isArray(data[relationsModels[relationName]])) {
              data[relationsModels[relationName]] = data[
                relationsModels[relationName]
              ].map(relationData =>
                beforeEdit(relationResource, lang, relationData)
              )
            } else {
              data[relationsModels[relationName]] = beforeEdit(
                relationResource,
                lang,
                data[relationsModels[relationName]]
              )
            }
          }
        }
      }
      return data
    }
    return data
  }

  /**
   * Overwrite current language translation with default language properties
   * @param {String} model Model instance
   * @param {String} lang Language code
   * @param {Object} data Reference of data
   */
  const overwriteWithDefault = function overwriteWithDefault (
    model,
    lang,
    data
  ) {
    const modelsProperties = mergeTranslatableKeys(model)
    Object.keys(modelsProperties).forEach(property => {
      if (!data.translations) {
        data.translations = {}
      }
      if (!data.translations[lang]) {
        data.translations[lang] = {}
      }
      data.translations[lang][property] =
        data[property] || modelsProperties[property]
    })
    if (fallbackTranslation) {
      if (!data.fallbackStatus) {
        data.fallbackStatus = {}
      }
      if (!data.fallbackStatus[lang]) {
        data.fallbackStatus[lang] = {}
      }
      Object.keys(modelsProperties).forEach(property => {
        data.fallbackStatus[lang][property] = false
      })
    }
  }
  /**
   * Set 'translationCompleted' value to true in translations[lang]
   * @param {String} lang Language code
   * @param {Object} data Reference of data
   * @param {Object} model Model's instance
   */
  const markAsComplete = async function markAsComplete (lang, data, model) {
    if (
      _.isUndefined(data.translations) ||
      _.isUndefined(data.translations[lang])
    ) {
      const mdToast = $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: '#content',
        theme: 'error-toast',
        hideDelay: 5000
      })
      $mdToast.show(mdToast)
      $mdToast.updateTextContent(
        $translate.instant('TRANSLATE.MARK_ERROR_MESSAGE')
      )
      return null
    } else {
      await model.markAsComplete({ id: data.id, language: lang })
      data.translations[lang].translationCompleted = true
      const mdToast = $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: '#content',
        theme: 'success-toast',
        hideDelay: 5000
      })
      $mdToast.show(mdToast)
      $mdToast.updateTextContent(
        $translate.instant('TRANSLATE.MARK_SUCCESS_MESSAGE')
      )
      return data
    }
  }
  /**
   * Set 'translationCompleted' value to false to all available languages
   * @param {Object} data Reference of data
   * @param {Object} model Model's instance
   */
  const markAsIncomplete = async function markAsIncomplete (data, model) {
    await model.markAsIncomplete({ id: data.id })
    availableLanguages.forEach(lang => {
      if (lang !== defaultLanguage) {
        if (_.isUndefined(data.translations)) {
          data.translations = {}
        }
        if (_.isUndefined(data.translations[lang])) {
          data.translations[lang] = {}
        }
        data.translations[lang].translationCompleted = false
      }
    })
    const mdToast = $mdToast.nextplus({
      position: $rootScope.toastLocation,
      parent: '#content',
      theme: 'success-toast',
      hideDelay: 5000
    })
    $mdToast.show(mdToast)
    $mdToast.updateTextContent(
      $translate.instant('TRANSLATE.MARK_INCOMPLETE_SUCCESS_MESSAGE')
    )
    return data
  }

  /**
   * Get all translatable keys for a given model
   * @param {Object|String} model Model instance or model name
   * @returns {Array} Array of translatable property keys
   */
  const getTranslatableKeys = function getTranslatableKeys (model) {
    const modelsProperties = mergeTranslatableKeys(model)
    return Object.keys(modelsProperties)
  }

  return {
    defaultLanguage,
    getForEdit,
    getForView,
    beforeEdit,
    overwriteWithDefault,
    markAsComplete,
    markAsIncomplete,
    createKeysForGrid,
    onSettings,
    getTranslatableKeys
  }
}

module.exports = MultiTranslateService

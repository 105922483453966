'use strict'
/* global angular, _ */
const UUID = require('uuid')

/** @ngInject */
function repeatingTemplate ($scope, $mdToast, $rootScope, $translate) {
  $scope.to.pushToEnd = $scope.to.pushToEnd || false
  $scope.formOptions = {
    formState: $scope.formState
  }

  $scope.startLimit = _.isUndefined($scope.to.limit) ? 0 : $scope.to.limit
  $scope.limit = $scope.startLimit

  $scope.showLess = function showLess () {
    $scope.limit = $scope.startLimit
  }

  $scope.loadMore = function loadMore () {
    $scope.limit += 10
  }

  const generateFieldIds = function generateFieldIds (fields) {
    return fields.map((field, index) => {
      if (field.fieldGroup) {
        field.fieldGroup = generateFieldIds(field.fieldGroup)
        return field
      }
      if (field.fields) {
        field.fields = generateFieldIds(field.fields)
        return field
      }
      field.id = field.id || field.key + '_' + index + '_' + UUID()
      return field
    })
  }

  $scope.copyFields = function copyFields (fields) {
    fields = angular.copy(fields)
    fields = generateFieldIds(fields)
    fields.forEach((field, idx) => {
      field.id = Math.floor(Math.random() * (10000 - 10)) + 10
      if (!field.expressionProperties) {
        field.expressionProperties = {}
        field.expressionProperties['templateOptions.disabled'] =
          'formState.disabled'
      }
    })
    return fields
  }
  $scope.addNew = function addNew () {
    const repeatsection = _.get($scope.model, $scope.options.key, [])
    let obj = {}
    if ($scope.to.addParameters) {
      obj = { ...obj, ...$scope.to.addParameters() }
    }
    if ($scope.to.pushToEnd) {
      repeatsection.push(obj)
    } else {
      repeatsection.unshift(obj)
    }
    _.set($scope.model, $scope.options.key, repeatsection)
    if ($scope.to.onChange) {
      $scope.to.onChange()
    }
  }
  $scope.removeField = function removeField (index) {
    if ($scope.to.handleRemove) {
      const repeatsection = _.get($scope.model, $scope.options.key, [])
      $scope.to.handleRemove(index, repeatsection[index], $scope.model)
      return
    }
    const optionsArray = _.get($scope.model, $scope.options.key, [])
    const field = optionsArray[index]
    if (field?.id === '0') {
      const mdToast = $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: 'body',
        theme: 'warning-toast',
        hideDelay: 3000
      })
      const errorMessage = $translate.instant(
        'COMMON.ERRORS.CANNOT_REMOVE_OPTION'
      )
      $mdToast.updateTextContent(errorMessage)
      $mdToast.show(mdToast)
      return
    }
    optionsArray.splice(index, 1)
    if ($scope.to.onChange) {
      $scope.to.onChange()
    }
  }
  $scope.onMoveUp = function onMoveUp (index) {
    const optionsArray = _.get($scope.model, $scope.options.key, [])
    const toIndex = index - 1
    swap(optionsArray, index, toIndex)
  }
  $scope.onMoveDown = function onMoveDown (index) {
    const optionsArray = _.get($scope.model, $scope.options.key, [])
    const toIndex = index + 1
    swap(optionsArray, index, toIndex)
  }
  const swap = function swap (array, fromIndex, toIndex) {
    const tempName = _.cloneDeep(array[fromIndex])
    array[fromIndex] = _.cloneDeep(array[toIndex])
    array[toIndex] = tempName
  }
  $scope.getFields = function getFields () {
    const fields = _.get($scope.model, $scope.options.key, [])
    if ($scope.to.filter) {
      return fields.filter($scope.to.filter)
    }
    return fields
  }
  $scope.fieldsOptions = { formState: { disabled: $scope.to.disabled } }
  $scope.$watch('to.disabled', function (newValue, oldValue, theScope) {
    $scope.fieldsOptions = { formState: { disabled: newValue } }
  })
}

module.exports = repeatingTemplate

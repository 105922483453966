/* global _ */
'use strict'

/** @ngInject */
function filePreview () {
  return {
    restrict: 'E',
    require: 'ngModel',
    template: require('./filePreview.html'),
    scope: {
      ngModel: '=',
      isMultiple: '='
    },
    /** @ngInject */
    controller: function ($scope, getUrlFromObj) {
      $scope.resource = $scope.ngModel
      $scope.resource.ext = $scope.resource.ext.toLowerCase()
      $scope.resourceUrl = getUrlFromObj($scope.ngModel)

      $scope.$watch('ngModel', function (newValue, oldValue, scope) {
        if (!_.isEqual(newValue, oldValue)) {
          $scope.resource = newValue
          $scope.resourceUrl = getUrlFromObj(newValue)
        }
      })
    }
  }
}

module.exports = filePreview

/* global _ kendo */
/** @ngInject */
function ApiMethodLogController (
  $scope,
  $rootScope,
  $translate,
  KendoGridHelper,
  ViewsService,
  Page,
  ApiMethodLog,
  ResolvedUsers,
  htmlWork,
  DateTimeFormatService
) {
  $scope.usersNameById =
    ResolvedUsers?.length > 0
      ? ResolvedUsers.reduce((obj, user) => {
          obj[user.id] = user.displayName
          return obj
        }, {})
      : {}
  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant('API_METHOD_LOG.TITLE')
    Page.setTitleText($scope.title)
    $scope.reqQueries = {}
    $scope.reqBodies = {}
    // $scope.resBodies = {}

    const tableColumns = [
      // USER
      {
        uniqueId: '0bb183d6-1ead-419f-8d46-83269005f256',
        field: 'userId',
        translateCode: 'API_METHOD_LOG.COLUMNS.USER',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'displayName',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: ResolvedUsers
                }),
                valuePrimitive: true
              })
            }
          }
        },
        trustedTemplate: data => {
          if (!data.userId || !$scope.usersNameById[data.userId]) return '--'
          return `<user-avatar
                    user-id="'${htmlWork.escapeHTMLQuotes(data.userId)}'"
                    display-name="usersNameById['${htmlWork.escapeHTMLQuotes(
                      data.userId
                    )}']"
                    show-name-inline="true"
                  ></user-avatar>`
        }
      },
      // DATE
      {
        uniqueId: '3d2b50de-ab6d-49ec-b6d1-939c5b351a71',
        field: 'created',
        translateCode: 'API_METHOD_LOG.COLUMNS.DATE',
        type: 'date',
        filterable: true,
        template: data => {
          return data.created === null
            ? '--'
            : DateTimeFormatService.formatDateTime(data.created, 'dateTime')
        }
      },
      // MODEL
      {
        uniqueId: '230105ce-1690-4f39-bf7d-31cf1827e173',
        field: 'model',
        translateCode: 'API_METHOD_LOG.COLUMNS.MODEL'
      },
      // NAME
      {
        uniqueId: 'f61e8261-a82c-4874-9d6e-5c7f93856888',
        field: 'name',
        translateCode: 'API_METHOD_LOG.COLUMNS.NAME'
        // media: '(min-width: 768px)'
      },
      // METHOD
      {
        uniqueId: '977c895b-ebfb-410c-a2cf-56c77a5f8f01',
        field: 'method',
        translateCode: 'API_METHOD_LOG.COLUMNS.METHOD'
      },
      // URL
      {
        uniqueId: 'e54ecfff-66cc-47bd-ba81-c5b60fa1eb94',
        field: 'url',
        translateCode: 'API_METHOD_LOG.COLUMNS.URL'
      },
      // REQ QUERY
      {
        uniqueId: '89a07964-3f18-4094-8cba-f9b6afb052f0',
        field: 'reqQuery',
        translateCode: 'API_METHOD_LOG.COLUMNS.REQ_QUERY',
        trustedTemplate: data => {
          if (_.isNil(data.reqQuery)) return '--'
          return `<json-formatter json="reqQueries['${data.id}']" open="0"></json-formatter>`
        }
      },
      // REQ BODY
      {
        uniqueId: 'dad04585-8939-47a6-9fbb-e8f26e48cf69',
        field: 'reqBody',
        translateCode: 'API_METHOD_LOG.COLUMNS.REQ_BODY',
        trustedTemplate: data => {
          if (_.isNil(data.reqBody)) return '--'
          return `<json-formatter json="reqBodies['${data.id}']" open="0"></json-formatter>`
        }
      }
      // // RES BODY
      // {
      //   uniqueId: '2aff95bc-39a2-424e-a4af-85a0e06f76c9',
      //   field: 'resBody',
      //   translateCode: 'API_METHOD_LOG.COLUMNS.RES_BODY',
      //   trustedTemplate: data => {
      //     if (_.isNil(data.resBody)) return '--'
      //     return `<json-formatter json="resBodies['${data.id}']" open="0"></json-formatter>`
      //   }
      // }
    ]

    const basicFields = {
      id: true,
      userId: true,
      created: true,
      model: true,
      name: true,
      method: true,
      url: true,
      reqQuery: true,
      reqBody: true,
      resBody: true
    }

    const { defaultTableColumns, customFieldsDisplay } =
      ViewsService.generateCustomFieldColumns('ApiMethodLog', tableColumns)

    const baseFilter = {
      where: {},
      order: 'created DESC',
      fields: _.merge(basicFields, customFieldsDisplay)
    }

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )
    const defaultTableSetup = {
      // stateName
      // ignoreParams
      model: ApiMethodLog,
      find: ApiMethodLog.find,
      count: ApiMethodLog.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns,
      pageSize: 10,
      autosize: true,
      extraDataBound: () => {
        $scope.kendoGrid.instance._data.forEach(item => {
          $scope.reqQueries[item.id] = item.reqQuery
            ? JSON.parse(item.reqQuery)
            : null
          $scope.reqBodies[item.id] = item.reqBody
            ? JSON.parse(item.reqBody)
            : null
          // $scope.resBodies[item.id] = item.resBody
          //   ? JSON.parse(item.resBody)
          //   : null
        })
      }
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-function',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title
  }
}

module.exports = ApiMethodLogController

const operatorsMap = require('./operatorsMap')
const moment = require('moment')
const jsonLogic = require('../json-logic-service')

const variableNames = [
  'today',
  'status',
  'created',
  'closedAt',
  'modified',
  'submittedForApproval',
  'approved',
  'rejected',
  'stockSku',
  'stockSerial',
  'stockLot',
  'partSku',
  'serial',
  'workorderNumber'
]
const valueIsField = function valueIsField (name) {
  return Array.isArray(name)
    ? false
    : /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
        name
      )
}

const isDateField = function isDateField (form, fieldsById, uniqueId) {
  const fieldObject = form.fields.find(f => f.id === uniqueId)
  if (!fieldObject) return false
  const field = fieldsById[fieldObject.fieldId]
  return field && field.type === 'datePicker'
}

const checkTriggerConditions = function checkTriggerConditions (
  conditions,
  formDataObject,
  originalModel,
  form,
  isNewInstance,
  fieldsById
) {
  let actions = []
  for (let i = 0; i < conditions.length; i++) {
    const condition = conditions[i]
    const jsonLogicObject = createJsonLogicObject(
      condition,
      'values',
      fieldsById,
      form
    )
    if (
      Object.values(jsonLogicObject)[0]?.length === 0 ||
      checkConditions(
        jsonLogicObject,
        formDataObject,
        originalModel,
        isNewInstance,
        fieldsById
      )
    ) {
      actions = actions.concat(condition.actions)
    }
  }
  return actions
}

const checkConditions = function checkConditions (
  conditions,
  formDataObject,
  originalModel,
  isNewInstance,
  fieldsById
) {
  const data = getDataForJsonLogic(formDataObject, fieldsById)

  data.oldFormData = getDataForJsonLogic(
    isNewInstance ? { fields: [] } : originalModel,
    fieldsById
  )
  data.isNewInstance = isNewInstance
  return jsonLogic.apply(conditions, data)
}

const getDataForJsonLogic = function getDataForJsonLogic (
  formDataObject,
  fieldsById
) {
  const data = {}
  variableNames.forEach(variableName => {
    if (variableName === 'today') {
      data[variableName] = moment(new Date()).toISOString()
      const dateWithoutTime = new Date()
      dateWithoutTime.setHours(0, 0, 0, 0)
      data.today_no_time = moment(dateWithoutTime).toISOString()
    } else {
      let variableValue = formDataObject[variableName] || null
      if (typeof variableValue === 'boolean') {
        variableValue = variableValue === true
      }
      data[variableName] = variableValue
    }
  })
  formDataObject.fields.forEach(field => {
    let value = null
    const fieldObject = fieldsById[field.fieldId]
    if (fieldObject) {
      switch (fieldObject.type) {
        case 'checkbox':
          value = !!(field.value === 'true' || field.value === true)
          break
        case 'datePicker':
        case 'dateTimePicker':
          value = field.value ? moment(field.value).toISOString() : null
          break
        default:
          value = field.value
      }
    } else {
      value =
        typeof field.value === 'boolean'
          ? !!(field.value === 'true' || field.value === true)
          : field.value && field.value instanceof Date
          ? moment(field.value).toISOString()
          : field.value
    }
    data[field.id] = value
  })
  return data
}

const checkFieldDisplayExpression = function checkFieldDisplayExpression (
  fieldSettings,
  field,
  formDataObject,
  originalModel,
  isNewInstance,
  fieldsById,
  form
) {
  let hide = false
  const options = []
  const optionDisplayObject = {}
  if (fieldSettings) {
    const { displayLogic, optionsDisplayLogic } = fieldSettings
    if (displayLogic) {
      const { rule, conditions } = displayLogic
      if (rule === 'show') {
        hide = false
      } else if (rule === 'hide') {
        hide = true
      } else {
        const conditionPass = checkConditions(
          createJsonLogicObject(conditions, 'rules', fieldsById, form),
          formDataObject,
          originalModel,
          isNewInstance,
          fieldsById
        )
        if (
          (rule === 'showWhen' && conditionPass) ||
          (rule === 'hideWhen' && !conditionPass)
        ) {
          hide = false
        } else if (
          (rule === 'showWhen' && !conditionPass) ||
          (rule === 'hideWhen' && conditionPass)
        ) {
          hide = true
        }
      }
    }
    if (optionsDisplayLogic) {
      optionsDisplayLogic.forEach(expression => {
        const { rule, conditions, options } = expression
        if (rule === 'show') {
          options.forEach(option => {
            optionDisplayObject[option] = true
          })
        } else if (rule === 'hide') {
          options.forEach(option => {
            if (!optionDisplayObject[option]) {
              optionDisplayObject[option] = false
            }
          })
        } else {
          const conditionPass = checkConditions(
            createJsonLogicObject(conditions, 'rules', fieldsById, form),
            formDataObject,
            originalModel,
            isNewInstance,
            fieldsById
          )
          if (
            (rule === 'showWhen' && conditionPass) ||
            (rule === 'hideWhen' && !conditionPass)
          ) {
            options.forEach(option => {
              optionDisplayObject[option] = true
            })
          } else if (
            (rule === 'showWhen' && !conditionPass) ||
            (rule === 'hideWhen' && conditionPass)
          ) {
            options.forEach(option => {
              if (!optionDisplayObject[option]) {
                optionDisplayObject[option] = false
              }
            })
          }
        }
      })
    }
  }
  field.options.forEach(option => {
    const value = option.id ? option.id : option.value
    if (
      typeof optionDisplayObject[value] === 'undefined' ||
      optionDisplayObject[value] === true
    ) {
      options.push(option)
    }
  })
  return {
    hide,
    options
  }
}
const createJsonLogicObject = function createJsonLogicObject (
  conditions,
  key = 'values',
  fieldsById,
  form
) {
  if (conditions.condition === 'OR' || conditions.condition === 'or') {
    conditions.condition = 'or'
  } else {
    conditions.condition = 'and'
  }
  const jsonLogicObject = {
    [conditions.condition]: []
  }
  conditions[key].forEach(rule => {
    if (rule.condition) {
      jsonLogicObject[conditions.condition].push(
        createJsonLogicObject(rule, key, fieldsById, form)
      )
    } else {
      const isBoolean =
        typeof rule.value === 'boolean' || rule.type === 'boolean'
      let value = isBoolean
        ? !!(rule.value === 'true' || rule.value === true)
        : rule.value

      if (valueIsField(value) || variableNames.includes(value)) {
        if (
          value === 'today' &&
          valueIsField(rule.field) &&
          isDateField(form, fieldsById, rule.field)
        ) {
          value = { var: 'today_no_time' }
        } else {
          value = { var: value }
        }
      } else if (Array.isArray(value)) {
        value = value.map(val =>
          valueIsField(val) || variableNames.includes(value)
            ? { var: val }
            : val
        )
      }
      if (
        rule.field === 'today' &&
        valueIsField(rule.value) &&
        isDateField(form, fieldsById, rule.value)
      ) {
        jsonLogicObject[conditions.condition].push({
          [operatorsMap[rule.operator]]: [{ var: 'today_no_time' }, value]
        })
      } else {
        jsonLogicObject[conditions.condition].push({
          [operatorsMap[rule.operator]]: [{ var: rule.field }, value]
        })
      }
    }
  })
  return jsonLogicObject
}

module.exports = {
  variableNames,
  checkTriggerConditions,
  checkFieldDisplayExpression
}

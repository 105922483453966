/* global _ */
'use strict'
const UUID = require('uuid')
/** @ngInject */
function ProductionEntitySplitController (
  $scope,
  $rootScope,
  $translate,
  ResolvedProductionEntityEvent,
  $mdDialog,
  $mdToast,
  ProductionEntityEvent,
  locals
) {
  const mainEvent = ResolvedProductionEntityEvent
    ? ResolvedProductionEntityEvent.toJSON()
    : {}

  const statuses = locals.statuses
  const mainStart = mainEvent.start
  const mainEnd = mainEvent.end
  $scope.cancel = function cancel () {
    $mdDialog.cancel()
  }
  $scope.save = function save () {
    ProductionEntityEvent.splitEvent({
      eventId: mainEvent.id,
      events: $scope.model.events
    })
      .$promise.then(() => {
        $rootScope.showSuccessToast(
          $translate.instant(
            'PRODUCTION_ENTITY.SPLIT_EVENT.EVENT_SPLIT_SUCCESSFULLY'
          )
        )
        $mdDialog.hide()
      })
      .catch(error => {
        console.error('Error splitting event:', error)
        const errorCode = error.data.error.code
        if (
          [
            'NO_EVENTS',
            'AT_LEAST_TWO_EVENTS_REQUIRED',
            'FIRST_EVENT_START_MISMATCH',
            'LAST_EVENT_END_MISMATCH',
            'GAP_OR_OVERLAP',
            'MISSING_START',
            'MISSING_END',
            'MISSING_STATUS',
            'END_BEFORE_START',
            'EVENT_START_BEFORE_MAIN_EVENT_START',
            'EVENT_END_AFTER_MAIN_EVENT_END'
          ].includes(errorCode)
        ) {
          $rootScope.showErrorToast(
            $translate.instant(
              `PRODUCTION_ENTITY.SPLIT_EVENT.ERRORS.${errorCode}`,
              error.data.error.details || {}
            )
          )
        } else {
          $rootScope.showErrorToast(
            $translate.instant(
              'PRODUCTION_ENTITY.SPLIT_EVENT.EVENT_SPLIT_ERROR'
            )
          )
        }
      })
  }

  const initScreen = function initScreen () {
    $scope.model = {
      start: mainStart,
      end: mainEnd,
      events: [
        {
          id: mainEvent.id,
          status: mainEvent.parameterId,
          start: mainStart,
          end: mainEnd
        }
      ]
    }
    $scope.fields = [
      {
        template:
          '<h2 style="margin-bottom: 0px;">' +
          $translate.instant(
            'PRODUCTION_ENTITY.SPLIT_EVENT.ORIGINAL_EVENT_TIMES'
          ) +
          '</h2>'
      },
      {
        wrapper: 'div',
        className: 'display-flex layout-xs-column layout-md-row',
        fieldGroup: [
          // EVENT_START_TIME
          {
            key: 'start',
            type: 'dateTimePicker',
            className: 'flex-50 margin-left-20 margin-right-20',
            templateOptions: {
              disabled: true,
              staticLabel: true,
              label: $translate.instant(
                'PRODUCTION_ENTITY.SPLIT_EVENT.EVENT_START_TIME'
              )
            }
          },
          // EVENT_END_TIME
          {
            key: 'end',
            type: 'dateTimePicker',
            className: 'flex-50 margin-left-20 margin-right-20',
            templateOptions: {
              disabled: true,
              staticLabel: true,
              label: $translate.instant(
                'PRODUCTION_ENTITY.SPLIT_EVENT.EVENT_END_TIME'
              )
            }
          }
        ]
      },
      {
        key: 'events',
        type: 'repeatingTemplate',
        templateOptions: {
          hideMovementButtons: true,
          layout: 'row',
          title: $translate.instant('PRODUCTION_ENTITY.SPLIT_EVENT.EVENTS'),
          btnText: $translate.instant('WF.ADD'),
          pushToEnd: true,
          onChange: function () {},
          handleRemove: function (index, item, model) {
            const field = $scope.model.events[index]
            if (field?.id === mainEvent.id) {
              const mdToast = $mdToast.nextplus({
                position: $rootScope.toastLocation,
                parent: 'body',
                theme: 'warning-toast',
                hideDelay: 3000
              })
              const errorMessage = $translate.instant(
                'COMMON.ERRORS.CANNOT_REMOVE_OPTION'
              )
              $mdToast.updateTextContent(errorMessage)
              $mdToast.show(mdToast)
              return
            }
            $scope.model.events.splice(index, 1)
            $scope.model.events[index - 1].end = mainEnd
            $scope.fields[2].templateOptions.onChange()
          },
          addParameters: function () {
            const params = {
              id: UUID(),
              status: null,
              start: null,
              end: mainEnd
            }
            // on add event remove the end time of the previous last event
            if ($scope.model.events[$scope.model.events.length - 1]) {
              $scope.model.events[$scope.model.events.length - 1].end = null
            }
            return params
          },
          fields: [
            {
              key: 'status',
              type: 'selectWithSearch',
              className: 'flex-40 margin-left-20 margin-right-20',
              templateOptions: {
                type: 'text',
                options: statuses.map(status => ({
                  id: status.id,
                  name: status.name,
                  color: status.color,
                  icon: 'icon-checkbox-blank-circle'
                })),
                required: true,
                label: $translate.instant(
                  'PRODUCTION_ENTITY.EVENT_FIELDS.STATUS'
                )
              }
            },
            {
              key: 'start',
              type: 'dateTimePicker',
              className: 'flex-50 margin-right-20',
              templateOptions: {
                required: true,
                label: $translate.instant(
                  'PRODUCTION_ENTITY.EVENT_FIELDS.START'
                ),
                onChange: function (value, options, model) {
                  // on change of start time , update the end time of the previous event
                  const index = _.findIndex(
                    $scope.model.events,
                    event => event.id === model.id
                  )
                  if (index > 0) {
                    $scope.model.events[index - 1].end = value
                  }
                }
              },
              expressionProperties: {
                'templateOptions.disabled': function (
                  $viewValue,
                  $modelValue,
                  scope
                ) {
                  return scope.model.id === mainEvent.id
                }
              }
            },
            {
              key: 'end',
              type: 'dateTimePicker',
              className: 'flex-50',
              templateOptions: {
                required: true,
                label: $translate.instant('PRODUCTION_ENTITY.EVENT_FIELDS.END'),
                onChange: function (value, options, model) {
                  // on change of end time , update the start time of the next event
                  const index = _.findIndex(
                    $scope.model.events,
                    event => event.id === model.id
                  )
                  if (index > -1 && index < $scope.model.events.length - 1) {
                    $scope.model.events[index + 1].start = value
                  }
                }
              },
              expressionProperties: {
                'templateOptions.disabled': function (
                  $viewValue,
                  $modelValue,
                  scope
                ) {
                  // disable if the event is the last event
                  const index = _.findIndex(
                    $scope.model.events,
                    event => event.id === scope.model.id
                  )
                  return index === $scope.model.events.length - 1
                }
              }
            }
          ]
        }
      }
    ]
  }

  initScreen()
}

module.exports = ProductionEntitySplitController

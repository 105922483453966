/* global angular _ */
'use strict'
import { isNullOrUndefined } from 'app/helper'
require('./selectUser.scss')
/** @ngInject */
function selectUserController (
  $rootScope,
  $scope,
  $timeout,
  $mdSelect,
  UserModel,
  getUrlFromObj
) {
  let input
  $scope.users = []
  $scope.customFilter = $scope.to.customFilter ? $scope.to.customFilter : null
  $scope.showConstants = _.isNil($scope.to.showConstants)
    ? true
    : $scope.to.showConstants
  $scope.showAssignee = $scope.to.showAssignee || false
  $scope.allowEmpty = $scope.to.allowEmpty || false
  $scope.alreadyAssignedToMe = false
  let allUsers = false
  const allUserParams = {
    filter: {
      fields: {
        id: true,
        displayName: true,
        profileResourceId: true,
        phone: true
      },
      include: ['resource']
    }
  }

  const focusInput = function focusInput () {
    $timeout(
      () => {
        const inputElement = document.querySelector(
          '.md-select-menu-container.md-active .selectboxWithSearch input'
        )
        if (inputElement) {
          inputElement.focus()
        }
      },
      200,
      false
    )
  }

  $scope.fillUsers = async function fillUsers (selectedUserIds = []) {
    focusInput()
    if (!allUsers) {
      if (selectedUserIds && !Array.isArray(selectedUserIds)) {
        selectedUserIds = [selectedUserIds]
      }
      let users = []
      if ($scope.customFilter) {
        if (selectedUserIds && selectedUserIds.length > 0) {
          allUserParams.filter.where = { id: { inq: selectedUserIds } }
        } else {
          allUserParams.filter.where = {
            and: [
              $scope.customFilter,
              {
                or: [{ active: true }]
              }
            ]
          }
          if (selectedUsers && selectedUsers.length > 0) {
            allUserParams.filter.where.and[1].or.push({
              id: { inq: selectedUsers }
            })
          }
          allUsers = true
        }
        if (selectedUserIds !== null) {
          users = await $rootScope.queryMemory.getData(
            'UserModel.find',
            allUserParams,
            () => UserModel.find(allUserParams).$promise,
            500
          )
        }
      } else {
        if (selectedUserIds && selectedUserIds.length) {
          allUserParams.filter.where = { id: { inq: selectedUserIds } }
        } else {
          allUserParams.filter.where = {
            or: [{ id: { inq: selectedUsers || [] } }, { active: true }]
          }
          allUsers = true
        }
        if (selectedUserIds !== null) {
          users = await $rootScope.queryMemory.getData(
            'UserModel.find',
            allUserParams,
            () => UserModel.find(allUserParams).$promise,
            500
          )
        }
      }
      $scope.users = users.map(user => ({
        id: user.id,
        name: user.displayName,
        profileResource: user.resource ? getUrlFromObj(user.resource) : null
      }))
      $scope.usersToDisplay = angular.copy($scope.users)
      $scope.$applyAsync()
    }
  }
  let selectedUsers = _.get($scope.model, $scope.options.key)

  const refreshSelectedUsers = () => {
    if (selectedUsers && !Array.isArray(selectedUsers)) {
      selectedUsers = [selectedUsers]
    }
    if (!isNullOrUndefined(selectedUsers) && selectedUsers.length > 0) {
      $scope.fillUsers(selectedUsers)
    }
  }
  refreshSelectedUsers()

  $scope.searchTerm = ''

  $scope.search = $event => {
    input = $event.target
    $scope.searchTerm = input.value
    $scope.debouncedFn(input.value)
  }

  $scope.onSelect = () => {
    if (!$scope.to.multiple) {
      $scope.usersToDisplay = angular.copy($scope.users)
      $mdSelect.hide()
    }
    if (allUsers && $scope.to.onChange) {
      $scope.to.onChange($scope.model[$scope.options.key], $scope.options)
    }
  }
  $scope.assignToMe = () => {
    $scope.users = [
      {
        id: $rootScope.currentUser.id,
        name: $rootScope.currentUser.displayName,
        profileResource: $rootScope.currentUser.resource
          ? getUrlFromObj($rootScope.currentUser.resource)
          : null
      }
    ]
    $scope.usersToDisplay = angular.copy($scope.users)

    if ($scope.to.multiple) {
      $scope.model[$scope.options.key] = [$rootScope.currentUser.id]
    } else {
      $scope.model[$scope.options.key] = $rootScope.currentUser.id
    }
    if (typeof $scope.to.onChange === 'function')
      $scope.to.onChange($scope.model[$scope.options.key], $scope.options)
  }
  $scope.clearSearchTerm = () => {
    if (input) {
      $scope.searchTerm = input.value
      input.value = ''
      $scope.makeSearch(input.value)
    }
  }

  $scope.makeSearch = word => {
    if (!word || word === '') {
      $scope.usersToDisplay = angular.copy($scope.users)
    } else {
      const users = angular.copy($scope.users).filter(user => {
        const userName = user.name.toLowerCase()
        return userName.includes($scope.searchTerm.toLowerCase())
      })
      $scope.usersToDisplay = users
    }
    $scope.$applyAsync()
  }

  $scope.debouncedFn = _.debounce($scope.makeSearch, 200)
  $scope.$watch(
    () => $scope.model[$scope.options.key],
    (newValue, oldValue) => {
      if (newValue) {
        if (Array.isArray(newValue)) {
          $scope.alreadyAssignedToMe = newValue.includes(
            $rootScope.currentUser.id
          )
        } else {
          $scope.alreadyAssignedToMe =
            newValue === $rootScope.currentUser.id
        }

        if (!_.isEqual(oldValue, newValue)) {
          selectedUsers = _.get($scope.model, $scope.options.key)
          refreshSelectedUsers()
        }
      }
    }
  )
}

module.exports = selectUserController

/* global angular _ moment */
import { mapOrder, paddingNumber, isDate } from 'app/helper'
const debug = require('debug')('nextplus:views-service')
/** @ngInject */
function ViewsService (
  $rootScope,
  $mdDialog,
  $state,
  $translate,
  $window,
  KendoGridHelper,
  htmlWork,
  DateTimeFormatService
) {
  const openViewDialog = function openViewDialog (
    stateName,
    title,
    columns,
    viewId,
    columnIds = [],
    filters = {}
  ) {
    return new Promise((resolve, reject) => {
      $mdDialog
        .show({
          controller: 'ViewsDialogController',
          locals: {
            state: stateName,
            title,
            columns,
            columnIds,
            filters,
            viewId
          },
          resolve: {
            ResolvedTableSetup: TableSetup => {
              if (viewId) {
                return TableSetup.findOne({ filter: { where: { id: viewId } } })
                  .$promise
              }
              return null
            }
          },
          template: require('../common/dialog/views.dialog.html'),
          parent: angular.element(document.body),
          multiple: true,
          fullscreen: true,
          clickOutsideToClose: false,
          escapeToClose: true
        })
        .then(
          function (res) {
            resolve(res)
          },
          function () {
            /* eslint-disable-next-line */
            reject()
          }
        )
    })
  }

  const getDefaultView = function getDefaultView (stateName) {
    if (
      $rootScope.appSettings.tableSetups &&
      $rootScope.appSettings.tableSetups[stateName]
    ) {
      const tableSetup = $rootScope.appSettings.tableSetups[stateName].find(
        tableSetup => tableSetup.default === true
      )
      if (tableSetup) return tableSetup
    }
    return null
  }

  const getUserView = function getUserView (stateName) {
    const modifiedStateName = stateName.replace(/\./g, '_') // eslint-disabled-line
    if ($rootScope.currentUser?.views?.[modifiedStateName]) {
      return $rootScope.currentUser.views[modifiedStateName]
    } else {
      const defaultTableSetup = getDefaultView(stateName)
      if (defaultTableSetup) return defaultTableSetup.id
    }
    return 'systemDefault'
  }

  const getTablesColumns = function getTablesColumns (columns, stateName) {
    if (!stateName) {
      stateName = $state.current.name
    }
    let selectedViewId = 'systemDefault'
    let filters = {}
    const userView = getUserView(stateName)
    const modifiedStateName = stateName.replace(/\./g, '_')
    const sessionCustomView = $window.sessionStorage.getItem(
      `custom-table-view-${modifiedStateName}`
    )
      ? JSON.parse(
          $window.sessionStorage.getItem(
            `custom-table-view-${modifiedStateName}`
          )
        )
      : null

    if (userView !== 'systemDefault') {
      if ($rootScope.appSettings?.tableSetups?.[stateName]) {
        const tableSetup = $rootScope.appSettings.tableSetups[stateName].find(
          tableSetup =>
            tableSetup.state === stateName && tableSetup.id === userView
        )

        if (tableSetup) {
          columns = _.cloneDeep(
            columns.filter(c => tableSetup.columns.includes(c.uniqueId))
          )
          columns = mapOrder(columns, tableSetup.columns, 'uniqueId')
          filters = tableSetup.filters || {}
          selectedViewId = tableSetup.id
        } else {
          const defaultTableSetup = getDefaultView(stateName)
          if (defaultTableSetup) {
            columns = _.cloneDeep(
              columns.filter(c =>
                defaultTableSetup.columns.includes(c.uniqueId)
              )
            )
            columns = mapOrder(columns, defaultTableSetup.columns, 'uniqueId')
            filters = defaultTableSetup.filters || {}
            selectedViewId = defaultTableSetup.id
          }
        }
      } else {
        columns = columns.filter(column => !column.hidden)
      }
    } else if (sessionCustomView) {
      // TODO: Asaf: session storage was created in order to allow to create temproary presist table view. This is not working as expected because its biscally overwrite any other view.
      columns = _.cloneDeep(
        columns.filter(c => sessionCustomView.columnIds.includes(c.uniqueId))
      )
      columns = mapOrder(columns, sessionCustomView.columnIds, 'uniqueId')
      filters = sessionCustomView.filters || {}
    } else {
      columns = columns.filter(column => !column.hidden)
    }

    const columnsWidth =
      JSON.parse($window.localStorage.getItem(`columns-width-${stateName}`)) ||
      {}
    if (!_.isEmpty(columnsWidth) && columnsWidth[selectedViewId]) {
      Object.keys(columnsWidth[selectedViewId]).forEach(columnId => {
        const column = _.find(columns, { uniqueId: columnId })
        if (column) {
          column.width = `${columnsWidth[selectedViewId][columnId]}px`
        }
      })
    }
    return { columns, selectedViewId, filters }
  }

  const createFilterObject = function createFilterObject (flt) {
    // convert bracket [ ] notation to dot notation
    const testRegex = new RegExp(/.+\.(.*)/m) // eslint-disable-line
    flt.field = flt.field.replace(/\[([^\]]+)\]/g, '.$1').replace(/"/g, '')
    // covert .UUID to UUID - for dynamic tables
    if (!testRegex.test(flt.field)) {
      flt.field = flt.field.replace(/\./g, '')
    }
    if (_.isString(flt.value)) {
      flt.value = flt.value.trim()
    }
    let convertedFilterObject = {}
    const dateTimeFormats =
      DateTimeFormatService.getDateTimeFormatsForKendoGrid()
    switch (flt.operator) {
      case 'equal':
        if (isDate(flt.value)) {
          const fromObj = {}
          const dateFrom = new Date(
            moment(flt.value, dateTimeFormats.date)
              .startOf('day')
              .add(1, 'days')
              .format(dateTimeFormats.date)
          )
          const fromOffSet = dateFrom.getTimezoneOffset() * 60000
          fromObj[flt.field] = {
            lt: new Date(dateFrom.getTime() + fromOffSet)
          }
          const toObj = {}
          const dateTo = new Date(
            moment(flt.value, dateTimeFormats.date)
              .endOf('day')
              .format(dateTimeFormats.date)
          )
          const toOffSet = dateTo.getTimezoneOffset() * 60000
          toObj[flt.field] = {
            gte: new Date(dateTo.getTime() + toOffSet)
          }
          convertedFilterObject = [fromObj, toObj]
        } else {
          convertedFilterObject[flt.field] = flt.value
        }
        break
      case 'not_equal':
        convertedFilterObject[flt.field] = {
          neq: flt.value
        }
        break
      case 'is_null':
        convertedFilterObject[flt.field] = {
          eq: null
        }
        break
      case 'is_not_null':
        convertedFilterObject[flt.field] = {
          neq: null
        }
        break
      case 'after':
      case 'greater':
        convertedFilterObject[flt.field] = {
          gt: flt.value
        }
        break
      case 'greater_or_equal':
        convertedFilterObject[flt.field] = {
          gte: flt.value
        }
        break
      case 'before':
      case 'less':
        convertedFilterObject[flt.field] = {
          lt: flt.value
        }
        break
      case 'less_or_equal':
        convertedFilterObject[flt.field] = {
          lte: flt.value
        }
        break
      case 'includes':
        convertedFilterObject[flt.field] = {
          like: '.*' + flt.value + '.*' /*eslint-disable-line*/,
          options: 'i'
        }
        break
      case 'between':
        if (isDate(flt.value)) {
          const fromObj = {}
          const dateFrom = new Date(
            moment(flt.value, dateTimeFormats.date)
              .startOf('day')
              .add(1, 'days')
              .format(dateTimeFormats.date)
          )
          const fromOffSet = dateFrom.getTimezoneOffset() * 60000
          fromObj[flt.field] = {
            lt: new Date(dateFrom.getTime() + fromOffSet)
          }
          const toObj = {}
          const dateTo = new Date(
            moment(flt.value2, dateTimeFormats.date)
              .endOf('day')
              .format(dateTimeFormats.date)
          )
          const toOffSet = dateTo.getTimezoneOffset() * 60000
          toObj[flt.field] = {
            gte: new Date(dateTo.getTime() + toOffSet)
          }
          convertedFilterObject = [fromObj, toObj]
        } else {
          convertedFilterObject = [
            { [flt.field]: { gte: flt.value } },
            { [flt.field]: { lte: flt.value2 } }
          ]
        }
        break
      case 'not_between':
        if (isDate(flt.value)) {
          const fromObj = {}
          const dateFrom = new Date(
            moment(flt.value, dateTimeFormats.date)
              .startOf('day')
              .add(1, 'days')
              .format(dateTimeFormats.date)
          )
          const fromOffSet = dateFrom.getTimezoneOffset() * 60000
          fromObj[flt.field] = {
            lt: new Date(dateFrom.getTime() + fromOffSet)
          }
          const toObj = {}
          const dateTo = new Date(
            moment(flt.value2, dateTimeFormats.date)
              .endOf('day')
              .format(dateTimeFormats.date)
          )
          const toOffSet = dateTo.getTimezoneOffset() * 60000
          toObj[flt.field] = {
            gt: new Date(dateTo.getTime() + toOffSet)
          }
          convertedFilterObject = [fromObj, toObj]
        } else {
          convertedFilterObject = [
            { [flt.field]: { lt: flt.value } },
            { [flt.field]: { gt: flt.value2 } }
          ]
        }
        break
      default:
        console.error('Filter operator ' + flt.operator + ' is not supported.')
    }
    return convertedFilterObject
  }

  const convertFiltersToLoopbackFilters =
    function convertFiltersToLoopbackFilters (filter) {
      const convertedFilter = {}
      const { logic, filters } = filter
      if (filters.length === 0) return {}
      convertedFilter[logic] = []
      for (let i = 0; i < filters.length; i++) {
        const filterObject = filters[i]
        if (
          _.isNil(filterObject.field) &&
          !_.isNil(filterObject.logic) &&
          filterObject.filters &&
          filterObject.filters.length
        ) {
          convertedFilter[logic].push(
            convertFiltersToLoopbackFilters(filterObject)
          )
        } else if (!_.isNil(filterObject.field)) {
          const convertedLoopbackFilterObject = createFilterObject(
            _.cloneDeep(filterObject)
          )
          if (_.isArray(convertedLoopbackFilterObject)) {
            convertedFilter[logic] = convertedFilter[logic].concat(
              convertedLoopbackFilterObject
            )
          } else {
            convertedFilter[logic].push(convertedLoopbackFilterObject)
          }
        }
      }
      return convertedFilter
    }

  const getViewCustomFilters = function getViewCustomFilters (
    selectedViewId,
    baseFilter,
    stateName
  ) {
    let newWhere = {}
    let filters = {}
    if (!stateName) {
      stateName = $state.current.name
    }
    if (!baseFilter.where) {
      baseFilter.where = {}
    }
    if (
      $rootScope.appSettings.tableSetups &&
      $rootScope.appSettings.tableSetups[stateName]
    ) {
      const tableSetup = $rootScope.appSettings.tableSetups[stateName].find(
        tableSetup =>
          tableSetup.state === stateName && tableSetup.id === selectedViewId
      )

      if (
        tableSetup &&
        tableSetup.filters &&
        !_.isEmpty(tableSetup.filters) &&
        tableSetup.filters.filters &&
        tableSetup.filters.filters.length > 0
      ) {
        filters = tableSetup.filters
        newWhere = convertFiltersToLoopbackFilters(tableSetup.filters)
      }
    }
    if (!_.isEmpty(newWhere) && !_.isEmpty(baseFilter.where)) {
      newWhere = { and: [newWhere, baseFilter.where] }
    } else if (!_.isEmpty(baseFilter.where)) {
      newWhere = baseFilter.where
    }
    baseFilter.where = newWhere
    return { newBaseFilter: baseFilter, filters }
  }

  const generateCustomFieldColumns = function generateCustomFieldColumns (
    modelName,
    defaultTableColumns
  ) {
    const customFieldsId = '1303016e-1852-46cf-bc25-cc37211a3'
    const customFieldsFields = {}
    const customFieldsColumns = []
    if (
      $rootScope.appSettings.modelsFields &&
      $rootScope.appSettings.modelsFields[modelName]
    ) {
      const modelSettings =
        $rootScope.appSettings.modelsFields[modelName].settings

      const modelCustomFieldsByKey = _.keyBy(
        $rootScope.appSettings.customFields.filter(field => {
          return field.modelName.includes(modelName)
        }),
        'key'
      )
      let translationPrefix = modelName
      if (!_.isUndefined(modelSettings) && modelSettings.i18nPrefix) {
        translationPrefix = modelSettings.i18nPrefix
      }
      const properties =
        $rootScope.appSettings.modelsFields[modelName].properties
      const propertiesNames = Object.keys(properties)
      for (let i = 0; i < propertiesNames.length; i++) {
        const propertyName = propertiesNames[i]
        const propertyObj = properties[propertyName]
        if (propertyObj.custom && propertyObj.display) {
          customFieldsFields[propertyObj.key] = true
          const idx = paddingNumber(i, 3)
          const column = {
            uniqueId: customFieldsId + idx,
            field: propertyObj.key,
            type: propertyObj.type.toLowerCase(),
            translateCode: $translate.instant(
              translationPrefix + '.' + propertyObj.key
            )
          }
          if (modelCustomFieldsByKey[propertyObj.key].type === 'array') {
            column.template = data =>
              typeof data[propertyObj.key].join === 'function'
                ? data[propertyObj.key].join(', ')
                : '--'
          }
          customFieldsColumns.push(column)
        }
      }
    }
    defaultTableColumns.splice(
      defaultTableColumns.length - 1,
      0,
      ...customFieldsColumns
    )
    return {
      defaultTableColumns,
      customFieldsDisplay: customFieldsFields
    }
  }

  const GridToolBarInstance = function (settings, tableRef, scope) {
    const that = {}
    const defaults = {
      stateName: $state.current.name,
      columns: [],
      currentColumnIds: [],
      filters: {},
      selectedViewId: null,
      title: null,
      allowDownload: false,
      allowAi: true,
      AiInjections: '',
      customButtons: []
    }
    return new Promise(resolve => {
      that.scope = scope
      that.tableRef = tableRef
      that.settings = _.defaultsDeep(settings, defaults)
      resolve({
        tableRef,
        getAllowDownload: function () {
          return that.settings ? that.settings.allowDownload : false
        },
        getAllowAi: function () {
          return that.settings ? that.settings.allowAi : true
        },
        getCustomButtons: function () {
          return that.settings ? that.settings.customButtons : []
        },
        getStateName: function () {
          return that.settings.stateName
        },
        getSelectedViewId: function () {
          return that.settings.selectedViewId
        },
        setSelectedViewId: function (selectedViewId) {
          that.tableRef.setSelectedViewId(selectedViewId)
          that.settings.selectedViewId = selectedViewId
        },
        getTitle: function () {
          return that.settings.title
        },
        getColumns: function () {
          return that.settings.columns
        },
        getColumnIds: function () {
          return that.settings.currentColumnIds
        },
        getFilters: function () {
          return that.settings.filters
        },
        fixSubFormColumns: function (columns) {
          const subFormMapping =
            KendoGridHelper.createComplexTypeMapping(columns)
          const subformColumnRegex =
            /\b[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}_[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\b/
          const allSubformColumns = columns.filter(c =>
            subformColumnRegex.test(c.uniqueId)
          )
          if (allSubformColumns.length > 0) {
            debug('fixSubFormColumns', allSubformColumns)
            allSubformColumns.forEach(column => {
              const isPrimary = subFormMapping.find(
                c => c.columnId === column.uniqueId
              )
              if (isPrimary) {
                that.tableRef.changeColumnAttributes(column.uniqueId, {
                  colspan: isPrimary.length,
                  style: 'display:table-cell;'
                })
              } else {
                that.tableRef.changeColumnAttributes(column.uniqueId, {
                  style: 'display:none;'
                })
              }
            })
          }
        },
        setColumnsAndFilters: function (columns, selectedViewId, filters) {
          this.fixSubFormColumns(columns)
          const convertedFilter = !_.isEmpty(filters)
            ? convertFiltersToLoopbackFilters(filters)
            : {}
          that.tableRef.setColumnsAndFilters(
            columns,
            selectedViewId,
            convertedFilter
          )
          that.settings.currentColumnIds = columns.map(c => c.uniqueId)
          that.settings.filters = filters
          that.settings.selectedViewId = selectedViewId
        },
        setColumnIds: function (columns, selectedViewId) {
          this.fixSubFormColumns(columns)
          that.tableRef.setColumns(columns, selectedViewId)
          that.settings.currentColumnIds = columns.map(c => c.uniqueId)
          that.settings.selectedViewId = selectedViewId
        },
        getTablesColumns: function () {
          return getTablesColumns(
            that.tableRef.instance.columns,
            that.settings.stateName
          ).columns
        },
        getTableData: function () {
          return that.tableRef.instance.dataSource.data()
        },
        getAiInjectionString: function () {
          const InjectionsTables = Object.keys(that.settings.AiInjections)
          let injectionsString = ''
          InjectionsTables.forEach(tableName => {
            injectionsString = injectionsString.concat(
              tableName,
              ' ',
              'value by id'
            )
            const data = that.settings.AiInjections[tableName]
            const columns = ['id', 'value']
            const csvData = []
            csvData.push(columns.map(column => column))
            data.forEach(row => {
              const rowArray = []
              columns.forEach(column => {
                rowArray.push(row[column])
              })
              csvData.push(rowArray)
            })
            const csv = csvData.map(row => row.join(',')).join('\n')
            injectionsString = injectionsString.concat('\n', csv, '\n')
          })
          return injectionsString
        },
        settings: that.settings
      })
    })
  }

  return {
    convertFiltersToLoopbackFilters,
    getViewCustomFilters,
    getTablesColumns,
    openViewDialog,
    getDefaultView,
    generateCustomFieldColumns,
    getUserView,
    GridToolBarInstance
  }
}

module.exports = ViewsService

/* global angular _ $ */

/** @ngInject */
function dynamicTable () {
  require('./dynamic-table.scss')
  return {
    template: require('./dynamic-table.html'),
    bindings: {
      tableName: '<',
      tableId: '<',
      columns: '<',
      extras: '<'
    },
    controller:
      /**
       * @ngInject
       */
      function dynamicTableController (
        $scope,
        $state,
        $translate,
        $mdDialog,
        Table,
        htmlWork,
        KendoGridHelper,
        ViewsService,
        PermissionUtils,
        DateTimeFormatService
      ) {
        const createColumns = function createColumns (columnDefinitions) {
          const columns = []
          for (let i = 0; i < columnDefinitions.length; i++) {
            const column = columnDefinitions[i]
            const columnObject = {
              uniqueId: column.id,
              field: `['${htmlWork.escapeHTMLQuotes(column.id)}']`,
              title: column.name,
              type: column.type,
              width: '200px',
              filterable: true
            }
            switch (column.type) {
              case 'date':
                columnObject.template = data => {
                  if (
                    _.isNil(data) ||
                    _.isNil(data[column.id]) ||
                    data[column.id] === ''
                  ) {
                    return ''
                  }
                  return DateTimeFormatService.formatDateTime(
                    data[column.id],
                    'dateTime'
                  )
                }
                break
              case 'resource':
                columnObject.filterable = false
                columnObject.sortable = false
                columnObject.trustedTemplate = data => {
                  if (
                    _.isNil(data) ||
                    _.isNil(data[column.id]) ||
                    data[column.id] === ''
                  ) {
                    return ''
                  }

                  return `<div layout="column" layout-align="center center" style="white-space: initial;">
                              <img
                                  md-lightbox
                                  lightbox-src="api/Resources/${htmlWork.escapeHTMLQuotes(
                                    data[column.id]
                                  )}/large/0"
                                  src="api/Resources/${htmlWork.escapeHTMLQuotes(
                                    data[column.id]
                                  )}/medium/0"/>
                              <a target="_blank" href="/api/Resources/download/${htmlWork.escapeHTMLQuotes(
                                data[column.id]
                              )}">
                                  <span translate="COMMON.DOWNLOAD"></span>
                              </a>
                          </div>`
                }
                break
              case 'boolean':
                break
              default:
                columnObject.template = data => {
                  if (_.isNil(data) || _.isNil(data[column.id])) {
                    return ''
                  }
                  return data[column.id]
                }
            }
            columns.push(columnObject)
          }
          columns.push({
            uniqueId: '81963424-52a8-4dc0-bb6e-da4350716d09',
            field: 'createdAt',
            translateCode: 'COMMON.CREATED',
            filterable: true,
            hidden: true,
            sortable: true,
            type: 'date',
            template: data => {
              return DateTimeFormatService.formatDateTime(
                data.createdAt,
                'dateTime'
              )
            }
          })
          columns.push({
            uniqueId: 'd2ec7b81-8e52-4985-9cc0-cf210a6878da',
            field: 'modified',
            type: 'date',
            hidden: true,
            translateCode: 'COMMON.MODIFIED',
            filterable: true,
            sortable: true,
            template: data => {
              return DateTimeFormatService.formatDateTime(
                data.modified,
                'dateTime'
              )
            }
          })
          columns.push({
            uniqueId: '19954060-7e34-441c-96db-50f8c8ef307b',
            field: 'workflowSessionItemId',
            type: 'string',
            hidden: true,
            translateCode: 'COMMON.SESSION_ID',
            filterable: true,
            sortable: true
          })
          if (
            PermissionUtils.isPermit('Table', 'removeRecord') ||
            PermissionUtils.isPermit('Table', 'updateRecord')
          ) {
            columns.push({
              uniqueId: '3ac48de2-c045-4b46-9b0e-55e755c1a8e2',
              field: 'id',
              translateCode: 'COMMON.ACTIONS',
              headerAttributes: {
                class: 'actions-column-header'
              },
              filterable: false,
              sortable: false,
              trustedTemplate: data => {
                return `<div layout="row" layout-align="start center">
                ${
                  PermissionUtils.isPermit('Table', 'updateRecord')
                    ? `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button']" ng-click="extras.createOrEditRecord('${data.id}')" data-testid="edit-${data.id}">
                            <md-icon md-font-icon="icon-pencil" class="s16"></md-icon>
                          </md-button>`
                    : ``
                }
                ${
                  PermissionUtils.isPermit('Table', 'removeRecord')
                    ? `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button']" data-testid="delete-${data.id}" ng-click="deleteRecord('${data.id}')">
                            <md-icon md-font-icon="icon-delete" class="s16 md-warn"></md-icon>
                          </md-button>
                        `
                    : ``
                }</div>`
              }
            })
          }
          return columns
        }

        $scope.deleteRecord = function deleteRecord (recordId) {
          const confirm = $mdDialog
            .confirm()
            .title($translate.instant('TABLES.DELETE_RECORD'))
            .targetEvent()
            .multiple(true)
            .clickOutsideToClose(true)
            .parent(angular.element(document.body))
            .ok($translate.instant('BUTTONS.DELETE'))
            .cancel($translate.instant('BUTTONS.CANCEL'))

          $mdDialog.show(confirm).then(
            function () {
              Table.removeRecord({
                tableId: $scope.tableId,
                recordId
              })
                .$promise.then(() => $scope.kendoGrid.reloadData())
                .catch(e => console.log(e))
            },
            function () {}
          )
        }

        const initTable = async function initTable () {
          const stateName = `${$state.current.name}.${$scope.tableId}`
          const tableColumns = createColumns($scope.columns)

          const { defaultTableColumns } =
            ViewsService.generateCustomFieldColumns('Table', tableColumns)

          const baseFilter = {
            where: { deletedAt: null },
            order: 'created DESC'
          }

          const { columns, selectedViewId } = ViewsService.getTablesColumns(
            defaultTableColumns,
            stateName
          )

          const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
            selectedViewId,
            _.cloneDeep(baseFilter),
            stateName
          )

          const defaultTableSetup = {
            stateName,
            // ignoreParams: true,
            find: Table.getTableList,
            count: Table.getTableCount,
            params: { tableId: $scope.tableId },
            cleanBaseFilter: baseFilter,
            baseFilter: newBaseFilter,
            selectedViewId,
            columns: defaultTableColumns,
            encodeTitles: true
          }

          const defaultTableToolbarSetup = {
            stateName,
            columns: defaultTableColumns,
            currentColumnIds: columns.map(c => c.uniqueId),
            filters,
            selectedViewId,
            allowDownload: true,
            title: $scope.title
          }

          $scope.downloadFunction = function downloadFunction () {
            const filter = $scope.kendoGrid.instance.dataSource.loopbackFilter
            const columns = []
            $scope.kendoGrid.instance.columns
              .filter(c => !c.hidden)
              .forEach(c => {
                if (!c.webonly) {
                  columns.push({
                    title: c.title,
                    field: c.field,
                    type: c.type
                  })
                }
              })
            const data = {
              filter,
              columns,
              tableId: $scope.tableId,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            const htmlForm = $('<form></form>')
              .attr('action', './api/Tables/exportTableData')
              .attr('method', 'post')
              .attr('target', '_blank')
            Object.keys(data).forEach(function (key) {
              const value = data[key]
              if (value instanceof Array || value instanceof Object) {
                htmlForm.append(
                  $('<input></input>')
                    .attr('type', 'hidden')
                    .attr('name', key)
                    .attr('value', JSON.stringify(value))
                )
              } else {
                htmlForm.append(
                  $('<input></input>')
                    .attr('type', 'hidden')
                    .attr('name', key)
                    .attr('value', value)
                )
              }
            })
            htmlForm.appendTo('body').submit().remove()
          }

          $scope.kendoGrid = await KendoGridHelper.GridInstance(
            defaultTableSetup,
            $scope,
            columns
          )

          await $scope.kendoGrid.isBound()

          $scope.tableToolbar = await ViewsService.GridToolBarInstance(
            defaultTableToolbarSetup,
            $scope.kendoGrid,
            $scope
          )

          $scope.$applyAsync()
        }

        this.$onInit = async () => {
          $scope.tableName = this.tableName
          $scope.tableId = this.tableId
          $scope.columns = this.columns
          await initTable()
          this.extras.reloadTable = () => $scope.kendoGrid.reloadData()
          $scope.extras = this.extras
        }
      }
  }
}

module.exports = dynamicTable
